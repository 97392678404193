import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Navbar from "@components/navbar"
import Footer from "@components/footer"
import Services from "@components/services"
import Announcement from "@components/announcement"

const CareersPage = () => (
  <Layout>
    <SEO title="Careers" />
    <div className="page-layout">
      <section className="intro intro--no-waves">
        <div className="container">
          <Navbar />
          <div className="container-blog container text-left">
            <h1 className="labs-title">
              Jobs at Labs42
              <span className="labs-blue">.</span>
            </h1>
          </div>
        </div>
      </section>
      <div className="intro-waves"></div>
      <section className="wave-wrapper">
        <section className="dark-bg page-wrapper">
          <div className="container-blog-content container">
            <div className="dark-bg page-container">
              <div>
                <div className="careers-page mt-n5">
                  <div className="positions-wrapper">
                    <div className="job-button text-left">
                      <h5>Senior Software Engineer</h5>
                      <i className="fas fa-globe-americas labs-blue"></i>
                      <span className="job-location">Chisinau, Moldova</span>
                      <a
                        href="https://jobs.diez.md/job/4154"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-secondary btn-more"
                      >
                        See more
                      </a>
                    </div>
                    <div className="job-button text-left">
                      <h5>Sales Development Representative</h5>
                      <i className="fas fa-globe-americas labs-blue"></i>
                      <span className="job-location">Remote</span>
                      <a
                        href="https://jobs.diez.md/job/8744"
                        target="_blank"
                        className="btn btn-secondary btn-more"
                        rel="noopener noreferrer"
                      >
                        See more
                      </a>
                    </div>
                  </div>
                  <div id="job-description"></div>
                </div>
                <div className="join-labs">
                  <div className="gradient-wrapper text-center">
                    <div className="join-wrapper">
                      <h2>
                        Interested in joining the Team
                        <span className="labs-blue">?</span>
                      </h2>
                      <p className="pt-3">
                        Our Team spans all across the globe, with a wide range
                        of expertise from everything from design to devops.
                      </p>
                      <br />
                      <a
                        href="mailto:hello@labs42.io"
                        className="btn btn-primary"
                      >
                        We'd love to hear from you!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="dark-bg"></section>
      </section>
      <div className="dark-bg">
        <section className="dark-bg wwd-wrapper pb-5">
          <Services />
        </section>
      </div>
      <Footer />
    </div>
  </Layout>
)

export default CareersPage
